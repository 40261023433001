import React from "react";
import "./styles.css";

interface Props {
  callback(): void;
  text: string;
  selected?: boolean;
  isFullWidth?: boolean;
}

const SelectButton: React.FC<Props> = ({
  text,
  selected = false,
  callback,
  isFullWidth = false,
}) => {
  return (
    <div className={`select-button ${isFullWidth ? "full-width" : ""}`}>
      <button
        onClick={callback}
        className={`select-button-box ${selected ? "btn-selected" : ""} ${
          isFullWidth ? "full-width" : ""
        }`}
      >
        <span
          className={`select-button-text ${selected ? "btn-selected" : ""}`}
        >
          {text}
        </span>
      </button>
    </div>
  );
};

export default SelectButton;
