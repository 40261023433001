import { useState } from "react";
import { getAvailability } from "../../services/RestaurantService.ts"; // Import your API functions
import { sortMealTimeAvailability } from "./utils.ts"; // Import your API functions
import {
  GetAvailabilityParams,
  RestaurantAvailability,
  RestaurantAvailabilityResponse,
  mockedRestaurantAvailability,
} from "../../types/types.ts";

const defaultRestaurantAvailability: RestaurantAvailability = {
  lunch: [],
  dinner: [],
};

export const useRestaurantAvailability = () => {
  const [availability, setAvailability] = useState<RestaurantAvailability>(
    defaultRestaurantAvailability
  );
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAvailability = async (params: GetAvailabilityParams) => {
    try {
      setLoading(true);
      const response = await getAvailability(params);
      if (response.status !== 200) {
        const error = response.data;
        throw error;
      } else {
        const data: RestaurantAvailability = response.data;
        console.log({ "[AvailabilityData]:": data });
        const sortedMealTimes = {
          lunch: sortMealTimeAvailability(data.lunch),
          dinner: sortMealTimeAvailability(data.dinner),
        };
        setAvailability(sortedMealTimes);
      }
    } catch (error) {
      console.error(
        error.message || "An error occurred while fetching availability"
      );
    } finally {
      setLoading(false);
    }
  };

  return { availability, loading, fetchAvailability };
};
