import React from "react";
import VirtualizedList from "./components/VirtualizedList/index.tsx";
import "./styles.css";
import SelectButton from "../TimeInput/components/SelectButton/index.tsx";

interface PratySizeInputProps {
  value?: number;
  onChange: (value: number) => void;
  maxSeats: number;
  isLoaded?: boolean;
}

const PartySizeInput: React.FC<PratySizeInputProps> = ({
  value,
  onChange,
  maxSeats,
  isLoaded
}) => {

  const ITEMS = Array.from({ length: maxSeats }, (_, i) => i + 1);

  const renderItem = (item: number) => (
    <div className="option-container">
      <SelectButton
        text={`${item} ${item > 1 ? "Personas" : "Persona"}`}
        selected={value === item}
        callback={() => {
          onChange(item);
        }}
        isFullWidth
      />
    </div>
  );

  if (!isLoaded) {
    return (
      <div className="party-input-container">
        <span className="no-tables-available-text">
          {"Buscando mesas disponibles..."}
        </span>
      </div>
    );
  }

  if (!maxSeats) {
    return (
      <div className="party-input-container">
        <span className="no-tables-available-text">
          {"No hay mesas disponibles para esta fecha"}
        </span>
      </div>
    );
  }

  return (
    <div className="party-input-container">
      <div className="title-container">
        <span className="step-title">Selecciona los comensales</span>
      </div>
      <div className="party-size-select">
        <VirtualizedList items={ITEMS} renderItem={renderItem} />
      </div>
    </div>
  );
};

export default PartySizeInput;
