import React from "react";
import "./styles.css";
import { Restaurant } from "../../../../types/types";

interface ReservationFormHeaderProps {
  restaurant: Restaurant;
}

const ReservationFormHeader: React.FC<ReservationFormHeaderProps> = ({
  restaurant,
}) => {
  const title = "Haz tu reserva";

  return (
    <div className="header-box">
      <span className="header-text">{title}</span>
      <div className="restaurant-data">
        <span className="subtitle-text name">{`en ${restaurant.name}`}</span>
        <span className="subtitle-text">
          {`${restaurant.address.address}, ${restaurant.address.addressNumber}, ${restaurant.address.zipCode}`}
        </span>
        <span className="subtitle-text">
          {`${restaurant.address.location.city}, ${restaurant.address.location.province}`}
        </span>
      </div>
    </div>
  );
};

export default ReservationFormHeader;
