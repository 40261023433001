import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";
import { CustomerData } from "../../types/types.ts";
import Input from "./components/Input/index.tsx";
import PhoneInput from "./components/PhoneInput/index.tsx";

interface ClientInputProps {
  onChange: (client: Partial<CustomerData>) => void;
  restaurantId: number;
  value: CustomerData | undefined;
  error: string | undefined;
  trigger: () => void;
}

const ClientInput: React.FC<ClientInputProps> = ({
  onChange,
  restaurantId,
  value,
  error,
  trigger,
}) => {
  useEffect(() => {
    onChange({
      restaurantId,
    });
  }, [restaurantId]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onChange({
      [name]: value,
    });
    trigger();
  };

  const handlePhoneChange = useCallback(
    (phoneNumber: string) => {
      onChange({
        phoneNumber,
      });
      trigger();
    },
    [onChange]
  );

  return (
    <div className="step-content">
      <div className="title-container">
        <span className="title">Completa tus datos</span>
      </div>
      <div className="form-container">
        <div className="client-form">
          <Input
            name="name"
            placeholder="Nombre completo"
            value={value?.name || ""}
            onChange={handleInputChange}
          />
          <Input
            name="email"
            placeholder="Correo"
            value={value?.email || ""}
            onChange={handleInputChange}
          />
          <PhoneInput
            name="phoneNumber"
            placeholder="Teléfono"
            value={value?.phoneNumber || ""}
            onChange={handlePhoneChange}
          />
          {error && <span className="errorDisplay">{error}</span>}
        </div>
      </div>
    </div>
  );
};

export default ClientInput;
