export const routes = {
  iam: {
    base: 'iam',
    children: {
      authentication: {
        base: 'iam/authentication',
        children: {
          signIn: 'sign-in',
          signUpManager: 'manager-sign-up',
          signUpPrivate: 'private-sign-up',
          updateToken: 'update-token',
          refreshToken: 'refresh-token',
          closeSession: 'close-session'
        }
      },
      users: {
        base: 'iam/users',
        children: {
          get_profile: 'profile'
        }
      }
    }
  },
  admins: {
    roots: {
      base: 'roots',
      children: {
        verifyManager: 'verify-manager'
      }
    }
  },
  managers: {
    base: 'managers',
    children: {
      validate: 'validate',
      getProfile: 'profile'
    }
  },
  restaurants: {
    base: 'restaurants',
    children: {
      common: {
        queries: {
          base: 'restaurants/queries',
          children: {
            search: 'search',
            recommendations: 'recommendations',
            provinces: 'provinces',
            availability: 'availability',
            capacity: 'capacity'
          }
        },
        services: {
          base: 'restaurants/common/services'
        },
        styles: {
          base: 'restaurants/common/styles'
        },
        zones: {
          base: 'restaurants/common/zones'
        },
        tables: {
          base: 'restaurants/common/tables',
          children: {
            getTablesAvailables: 'get-tables-availables'
          }
        }
      },
      contacts: {
        base: 'restaurants/contacts'
      }
    }
  },
  bookings: {
    base: 'restaurants/bookings',
    createFromContact: 'bookings-manager',
    createFromIFrame: 'bookings-iframe',
    updateBookingState: 'update-booking-state',
    children: {
      queries: {
        base: 'restaurants/bookings/queries',
        children: {
          restaurants: 'restaurant/:id',
          searchFromTable: 'search-from-table',
          userBookings: 'user-bookings',
          search: 'search'
        }
      }
    }
  },
  notices: {
    base: 'users/notices'
  }
};
