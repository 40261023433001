import React from 'react';
import myImage from './success-hero.png';
import './styles.css';

interface ResponsiveImageProps {
  src?: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({ src = myImage, alt='', className = '', style }) => {
  return (
    <div className={`image-container ${className}`} style={style}>
      <img src={src} alt={alt} className="image" />
    </div>
  );
};

export default ResponsiveImage;
