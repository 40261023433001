import { functions } from "../firebaseConfig.ts";
import {
  CreateBooking,
  GetAvailabilityParams,
  GetRestaurantMaxSeatsByDayResponse,
  newCreateBookingFromCreateBooking,
  Restaurant,
  RestaurantAvailability,
  RestaurantDto,
} from "../types/types.ts";
import { apiClient } from "../utils/api/ApiClient.ts";
import { routes } from "../utils/api/routes.ts";

export interface GetRestaurantParams {
  restaurantId: number;
}
export const getRestaurant = async (params: GetRestaurantParams) => {
  return apiClient.get<RestaurantDto>(
    `${routes.restaurants.base}/?restaurantId=${params.restaurantId}`
  );
};

export interface CreateBookingParams extends CreateBooking {}
export const createBooking = async (params: CreateBookingParams) => {
  const data = newCreateBookingFromCreateBooking(params);
  return apiClient.post(
    `${routes.bookings.base}/${routes.bookings.createFromIFrame}`,
    data
  );
};

export const getAvailability = async (params: GetAvailabilityParams) => {
  console.log("getAvailability", params);
  return apiClient.post<RestaurantAvailability>(
    `${routes.restaurants.children.common.queries.base}/${routes.restaurants.children.common.queries.children.availability}`,
    params
  );
};

export const getMaxSeats = async (params: GetAvailabilityParams) => {
  return apiClient.post<GetRestaurantMaxSeatsByDayResponse>(
    `${routes.restaurants.children.common.queries.base}/${routes.restaurants.children.common.queries.children.capacity}`,
    params
  );
};
