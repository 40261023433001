import { APP_FORM_STEPS } from "../utils/constants";

export enum ClientType {
  WalkIn,
  User,
  Contact,
}

export interface Table {
  id: string;
  name: string;
  seats: number;
  zoneId: string;
}

export type Slot = "lunch" | "dinner";

export interface MealTime {
  hour: string;
  table?: Table;
  slot?: Slot;
}

export type Allergies = "yes" | "no" | "unconfirmed" | "";

export enum StepNavigatorMenuButtonType {
  LEFT = "left",
  RIGHT = "right",
}

export interface UserData {
  disabled: boolean;
  displayName: string;
  email: string;
  emailVerified: boolean;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  uid: string;
}

export interface Contact {
  id: string;
  emails: string[];
  name: string;
  phoneNumber: string;
  user?: UserData;
}

export interface CreateBooking {
  id?: string;
  allergies: string;
  comment: string;
  table?: Table;
  restaurantId: number;
  numberOfPeople: number;
  date: string;
  bracket: Slot;
  customerInfo?: CustomerDetails;
}
export interface NewCreateBooking {
  restaurantId: number;
  allergies: string;
  comment: string;
  table?: Table;
  seats: number;
  date: string;
  slot: Slot;
  customerInfo?: CustomerDetails;
}
export const newCreateBookingFromCreateBooking = (
  booking: CreateBooking
): NewCreateBooking => {
  return {
    restaurantId: booking.restaurantId,
    allergies: booking.allergies,
    comment: booking.comment,
    table: booking.table,
    seats: booking.numberOfPeople,
    date: booking.date,
    slot: booking.bracket,
    customerInfo: booking.customerInfo,
  };
}

export interface CustomerData {
  restaurantId?: number;
  name?: string;
  phoneNumber?: string;
  email?: string;
}

export interface CustomerDetails {
  restaurantId: number;
  name: string;
  phoneNumber: string;
  emails: string[];
}

export enum BookingState {
  PENDING = "1", //pendiente
  BOOKED = "2", //reservada
  CONFIRMED = "3", //confirmada
  ON_GOING = "4", //en curso. para el cliente no existe este estado.
  NO_SHOW = "5", //no presentado
  FINISHED = "6", //terminada. para el cliente: disfrutada.
  REJECTED = "7", //rechazada por el manager
  CANCELLED = "8", //cancelada por el usuario
}
export interface Booking {
  UUID: string;
  id: string;
  state: BookingState;
  allergies?: Allergies;
  comment: string;
  table: Table;
  restaurantId: number;
  numberOfPeople: number;
  customerId: string;
  contactId: string;
  date: Date;
  bracket: Slot;
  customerInfo?: CustomerInfo;
  restaurantName?: string;
}
export interface CustomerInfo {
  email: string;
  id: string;
  name: string;
  phoneNumber: string;
}
export interface Ledger {
  url?: string;
  base64?: string;
  fileName?: string;
  reference?: string;
}

export interface Restaurant {
  active: boolean;
  address: RestaurantAddress;
  automatedBooking: boolean;
  description: string;
  details: RestaurantDetails;
  id: number;
  name: string;
  schedule: Schedule[];
  cancelationPolicy?: CancellationPolicy;
  bookingDuration: number;
  verified: boolean;
  ledger: Ledger;
  tables?: Table[];
  zones: Zone[];
  bookings: Booking[];
  contacts: Contact[];
}
export interface RestaurantStyle {
  id: string;
  name: string;
}
export interface RestaurantService {
  id: string;
  name: string;
}
export interface Image {
  reference?: string;
  content64?: string;
  fileName: string;
  url?: string;
}
export interface PriceRange {
  currencyCode: string;
  maximum: number;
  minimum: number;
}
export interface RestaurantDetails {
  styles: RestaurantStyle[];
  bannersImages: Image[];
  foodImages: Image[];
  pricesRange: PriceRange;
  services: RestaurantService[];
  banner: string;
}
export interface Location {
  latitude: string;
  longitude: string;
  city: string;
  country: string;
  province: string;
  placeId: string;
}
export interface RestaurantAddress {
  location: Location;
  address: string;
  addressNumber: string;
  zipCode: string;
}
export interface BookingDto {
  UUID: string;
  id: string;
  state: BookingState;
  allergies?: Allergies;
  comment: string;
  table: Table;
  restaurantId: number;
  numberOfPeople: number;
  customerId: string;
  contactId: string;
  date: string;
  bracket: Slot;
  customerInfo?: CustomerInfo;
  restaurantName?: string;
}
export interface Schedule {
  dinner: string[];
  lunch: string[];
}

export interface CancellationPolicy {
  minutes: number;
  description: string;
}

export interface Zone {
  id: string;
  name: string;
  tables: Table[];
}

export interface UserData {
  disabled: boolean;
  displayName: string;
  email: string;
  emailVerified: boolean;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  uid: string;
}

export interface Contact {
  id: string;
  emails: string[];
  name: string;
  phoneNumber: string;
  user?: UserData;
}

export const DefaultRestaurant: Restaurant = {
  active: true,
  address: {
    location: {
      latitude: "",
      longitude: "",
      city: "",
      country: "",
      province: "",
      placeId: "",
    },
    address: "",
    addressNumber: "",
    zipCode: "",
  },
  automatedBooking: false,
  description: "",
  details: {
    styles: [],
    bannersImages: [],
    foodImages: [],
    pricesRange: {
      currencyCode: "",
      maximum: 0,
      minimum: 0,
    },
    services: [],
    banner: "",
  },
  id: 0,
  name: "",
  schedule: [],
  cancelationPolicy: {
    minutes: 0,
    description: "",
  },
  bookingDuration: 0,
  verified: false,
  ledger: {},
  tables: [],
  zones: [],
  bookings: [],
  contacts: [],
};

interface LedgerDto {
  url?: string;
  base64?: string;
  fileName?: string;
}

interface UserDto {
  disabled: boolean;
  displayName: string;
  email: string;
  emailVerified: boolean;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  uid: string;
}

export interface ManagerDto {
  name: string;
  role: string;
  user: UserDto;
  verified: boolean;
}

export interface ContactDto {
  id: string;
  emails: string[];
  name: string;
  phoneNumber: string;
  user: UserDto;
}

export interface RestaurantDto {
  active: boolean;
  address: RestaurantAddressDto;
  automatedBooking: boolean;
  // contacts: null;
  description: string;
  details: RestaurantDetailsDto;
  // employees: null;
  durationReserveMinutes: number;
  schedule: ScheduleDto[];
  id: string;
  name: string;
  cancelationPolicy: CancellationPolicyDto;
  verified: boolean;
  ledger: LedgerDto;
  // tables: TableDto[];
  zones: ZoneDto[];
  bookings: BookingDto[];
  contacts: ContactDto[];
}

export interface TableDto {
  id: string;
  name: string;
  seats: number;
  zoneId: string;
}
export interface ZoneDto {
  id: string;
  name: string;
  tables: TableDto[];
}

export interface CancellationPolicyDto {
  minutes: number;
  description: string;
}

export interface PriceRangeDto {
  maximum: number;
  minimum: number;
  currencyCode: string;
}

export interface RestaurantDetailsDto {
  styles: any[];
  bannersImages: any[];
  foodImages: any[];
  pricesRange: PriceRangeDto;
  services: any[];
  banner: string;
}

export interface RestaurantAddressDto {
  location: Location;
  address: string;
  addressNumber: string;
  zipCode: string;
}

export interface ScheduleDto {
  dinner: string[];
  lunch: string[];
}
export interface StepForDisplay {
  id: string;
  icon: string;
  label: string;
  isCompleted: boolean | string;
  isCurrentStep: boolean;
  name: string;
  type: APP_FORM_STEPS;
}
export interface GetAvailabilityParams {
  restaurantId: number;
  date: string;
}
export interface MealTimeAvailability {
  hour: string;
  tables: Table[];
}
export interface RestaurantAvailability {
  lunch: MealTimeAvailability[];
  dinner: MealTimeAvailability[];
}
export interface RestaurantAvailabilityResponse {
  content: RestaurantAvailability;
}

export const mockedRestaurant: Restaurant = {
  active: true,
  address: {
    location: {
      latitude: "40.712776",
      longitude: "-74.005974",
      city: "New York",
      country: "USA",
      province: "New York",
      placeId: "ChIJpVerXK5YwokR5JIC-U7iHl4",
    },
    address: "123 Main St",
    addressNumber: "123",
    zipCode: "10001",
  },
  automatedBooking: true,
  description:
    "A fine dining restaurant with a diverse menu and excellent service.",
  details: {
    styles: [
      { id: "1", name: "Fine Dining" },
      { id: "2", name: "Casual" },
    ],
    bannersImages: [
      { fileName: "banner1.jpg", url: "https://example.com/banner1.jpg" },
      { fileName: "banner2.jpg", url: "https://example.com/banner2.jpg" },
    ],
    foodImages: [
      { fileName: "food1.jpg", url: "https://example.com/food1.jpg" },
      { fileName: "food2.jpg", url: "https://example.com/food2.jpg" },
    ],
    pricesRange: {
      currencyCode: "USD",
      maximum: 100,
      minimum: 20,
    },
    services: [
      { id: "1", name: "WiFi" },
      { id: "2", name: "Parking" },
    ],
    banner: "https://example.com/banner.jpg",
  },
  id: 1,
  name: "The Gourmet Kitchen",
  schedule: [
    { dinner: ["18:00", "21:00"], lunch: ["12:00", "15:00"] },
    { dinner: ["18:00", "21:00"], lunch: ["12:00", "15:00"] },
  ],
  cancelationPolicy: {
    minutes: 30,
    description:
      "Cancellations are allowed up to 30 minutes before the reservation time.",
  },
  bookingDuration: 90,
  verified: true,
  ledger: {
    url: "https://example.com/ledger.pdf",
    fileName: "ledger.pdf",
  },
  tables: [
    { id: "table1", name: "Table 1", seats: 4, zoneId: "zone1" },
    { id: "table2", name: "Table 2", seats: 2, zoneId: "zone1" },
    { id: "table3", name: "Table 3", seats: 4, zoneId: "zone1" },
    { id: "table4", name: "Table 4", seats: 4, zoneId: "zone2" },
    { id: "table5", name: "Table 5", seats: 4, zoneId: "zone2" },
  ],
  zones: [
    {
      id: "zone1",
      name: "Main Hall",
      tables: [
        { id: "table1", name: "Table 1", seats: 4, zoneId: "zone1" },
        { id: "table2", name: "Table 2", seats: 2, zoneId: "zone1" },
        { id: "table3", name: "Table 3", seats: 4, zoneId: "zone1" },
      ],
    },
    {
      id: "zone2",
      name: "VIP Room",
      tables: [
        { id: "table4", name: "Table 4", seats: 4, zoneId: "zone2" },
        { id: "table5", name: "Table 5", seats: 4, zoneId: "zone2" },
      ],
    },
  ],
  bookings: [
    {
      UUID: "booking123",
      id: "1",
      state: BookingState.BOOKED,
      allergies: "no",
      comment: "Looking forward to the meal.",
      table: { id: "table1", name: "Table 1", seats: 4, zoneId: "zone1" },
      restaurantId: 1,
      numberOfPeople: 4,
      customerId: "cust123",
      contactId: "contact123",
      date: new Date("2024-06-01T18:00:00Z"),
      bracket: "dinner",
      customerInfo: {
        email: "customer@example.com",
        id: "cust123",
        name: "John Doe",
        phoneNumber: "1234567890",
      },
      restaurantName: "The Gourmet Kitchen",
    },
  ],
  contacts: [
    {
      id: "contact123",
      emails: ["customer@example.com"],
      name: "John Doe",
      phoneNumber: "1234567890",
      user: {
        disabled: false,
        displayName: "John Doe",
        email: "customer@example.com",
        emailVerified: true,
        phoneNumber: "1234567890",
        phoneNumberVerified: true,
        uid: "user123",
      },
    },
  ],
};

// export default mockedRestaurant;

// Mock Tables
const tables: Table[] = [
  { id: "table1", name: "Table 1", seats: 4, zoneId: "zone1" },
  { id: "table2", name: "Table 2", seats: 2, zoneId: "zone1" },
  { id: "table3", name: "Table 3", seats: 6, zoneId: "zone2" },
  { id: "table4", name: "Table 4", seats: 8, zoneId: "zone2" },
];

// Mock MealTimeAvailability for lunch and dinner
const lunchAvailability: MealTimeAvailability[] = [
  {
    hour: "12:00",
    tables: [tables[0], tables[1]],
  },
  {
    hour: "13:00",
    tables: [tables[1], tables[2]],
  },
  {
    hour: "14:00",
    tables: [tables[2], tables[3]],
  },
];

const dinnerAvailability: MealTimeAvailability[] = [
  {
    hour: "18:00",
    tables: [tables[0], tables[2]],
  },
  {
    hour: "19:00",
    tables: [tables[1], tables[3]],
  },
  {
    hour: "20:00",
    tables: [tables[0], tables[3]],
  },
];

// Mock RestaurantAvailability
export const mockedRestaurantAvailability: RestaurantAvailability = {
  lunch: lunchAvailability,
  dinner: dinnerAvailability,
};

// export default mockRestaurantAvailability;

export interface RestaurantAvailability {
  lunch: MealTimeAvailability[];
  dinner: MealTimeAvailability[];
}

export interface MealTimeAvailability {
  hour: string;
  tables: TableInfo[];
}

export interface TableInfo {
  id: string;
  name: string;
  seats: number;
  zoneId: string;
}

export interface GetRestaurantMaxSeatsByDayResponse {
  lunch: number;
  dinner: number;
}
