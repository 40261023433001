import React from "react";
import "./styles.css";
import SelectButton from "../TimeInput/components/SelectButton/index.tsx";
import { Allergies } from "../../types/types.ts";

interface AllergiesInputProps {
  value?: Allergies;
  onChange: (value: Allergies) => void;
}

const AllergiesInput: React.FC<AllergiesInputProps> = ({ value, onChange }) => {
  return (
    <div className="allergies-container">
      <div className="title-container">
        <span className="allergies-title">{"Alergias"}</span>
      </div>
      <div className="allergies-content">
        <div className="subtitle-container">
          <p className="allergies-subtitle">
            ¿Tienen alguno de los comensales, algún tipo de alergia alimentaria?
          </p>
        </div>
        <div className="select-buttons">
          <SelectButton
            selected={value === "yes"}
            text="Si"
            callback={() => onChange("yes")}
          />
          <SelectButton
            selected={value === "no"}
            text="No"
            callback={() => onChange("no")}
          />
          <SelectButton
            selected={value === "unconfirmed"}
            text="Confirmar más tarde"
            callback={() => onChange("unconfirmed")}
          />
        </div>
      </div>
    </div>
  );
};

export default AllergiesInput;
