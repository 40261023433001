import React from "react";
import "./styles.css";

interface VirtualizedListProps<T> {
  items: T[];
  itemHeight?: number;
  renderItem: (item: T, index: number) => React.ReactNode;
}

const VirtualizedList = <T,>({
  items,
  renderItem,
}: VirtualizedListProps<T>) => {
  const renderedItems = items.map((item, index) => (
    <div key={index} className="virtualized-list-item">
      {renderItem(item, index)}
    </div>
  ));

  return (
    <div className="virtualized-list-container">
      <div className="virtualized-list-inner">
        <div className="virtualized-list-content">{renderedItems}</div>
      </div>
    </div>
  );
};

export default VirtualizedList;
