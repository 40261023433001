import { useState } from "react";
import { getMaxSeats } from "../../services/RestaurantService.ts";
import {
  GetAvailabilityParams,
  RestaurantAvailability,
} from "../../types/types.ts";
import { getAvailableSeats } from "./utils.ts";

const defaultRestaurantAvailability: RestaurantAvailability = {
  lunch: [],
  dinner: [],
};

export const useRestaurantMaxSeats = () => {
  const [maxSeats, setMaxSeats] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchMaxSeats = async (params: GetAvailabilityParams) => {
    try {
      setLoading(true);
      const response = await getMaxSeats(params);
      if (response.status !== 200) {
        const error = response.data;
        throw error;
      } else {
        const data = response.data;
        setMaxSeats(Math.max(data.lunch, data.dinner));
        console.log({ "[MaxSeatsData]:": data });
      }
    } catch (error) {
      console.error(
        error.message || "An error occurred while fetching available seats"
      );
    } finally {
      setLoading(false);
    }
  };

  return { maxSeats, loading, fetchMaxSeats };
};
