import React, { useMemo } from "react";
import Icon, {
  AlertCircleOutline,
  CalendarOutline,
  ChatbubbleOutline,
  PeopleOutline,
  PersonOutline,
  TimeOutline,
} from "react-ionicons";
import MesaOutline from "./assets/MesaOutline.tsx";
import "./styles.css";
import { COLORS } from "../../../../utils/colors.tsx";
import { stepsForDisplay } from "../../../../pages/ReservationForm/index.tsx";

export interface ReservationDisplayStepProps {
  icon: string;
  label: string;
  isCompleted: boolean | string;
  isSelected: boolean;
}

const ReservationDisplayStep: React.FC<ReservationDisplayStepProps> = ({
  icon,
  isCompleted,
  isSelected,
  label,
}) => {
  const isHighlighted = useMemo(() => {
    return isSelected || isCompleted;
  }, [isSelected, isCompleted]);

  const renderIcon = () => {
    switch (icon) {
      case stepsForDisplay[0].icon:
        return (
          <PersonOutline
            height="24px"
            width="24px"
            color={isHighlighted ? "white" : COLORS.lightblue}
          />
        );
      case stepsForDisplay[1].icon:
        return (
          <CalendarOutline
            height="24px"
            width="24px"
            color={isHighlighted ? "white" : COLORS.lightblue}
          />
        );
      case stepsForDisplay[2].icon:
        return (
          <PeopleOutline
            height="24px"
            width="24px"
            color={isHighlighted ? "white" : COLORS.lightblue}
          />
        );
      case stepsForDisplay[3].icon:
        return (
          <TimeOutline
            height="24px"
            width="24px"
            color={isHighlighted ? "white" : COLORS.lightblue}
          />
        );
      case stepsForDisplay[4].icon:
        return (
          <AlertCircleOutline
            height="24px"
            width="24px"
            color={isHighlighted ? "white" : COLORS.lightblue}
          />
        );
      case stepsForDisplay[5].icon:
        return (
          <ChatbubbleOutline
            height="24px"
            width="24px"
            color={isHighlighted ? "white" : COLORS.lightblue}
          />
        );
      default:
        return (
          <MesaOutline
            color={isHighlighted ? COLORS.white : COLORS.lightblue}
            size={24}
          />
        );
    }
  };

  return (
    <div className="reservation-display-container">
      {renderIcon()}
      <div
        className={`text-container ${
          isSelected ? "text-container-selected" : ""
        }`}
      >
        <span className={`text ${isHighlighted ? "text-highligted" : ""}`}>
          {label}
        </span>
      </div>
    </div>
  );
};

export default ReservationDisplayStep;
