import React from 'react';
import './styles.css';

export interface ButtonsConfig {
  title: string;
  boxLayout?: string;
  disabled?: boolean;
  callback(): void;
  isLoading?: boolean;
  btnStyle?: keyof ButtonStyles;
  startIcon?: string;
  isFullWidth?: boolean;
  textColor?: string;
  whiteText?: boolean;
}

interface ButtonStyles {
  [index: string]: string;
  regular: string;
  outline: string;
  white: string;
}

const buttonStyles: ButtonStyles = {
  regular: 'buttonRegular',
  outline: 'buttonOutline',
  white: 'buttonWhite'
};
const textOutline: ButtonStyles = {
  regular: 'textRegular',
  outline: 'textOutline',
  white: 'textWhite'
};

// const BUTTON_HEIGHT = 48;

const MainButton: React.FC<ButtonsConfig> = ({
  disabled,
  isLoading = false,
  callback,
  title,
  boxLayout = '',
  btnStyle = 'regular',
  isFullWidth = false,
  textColor,
  whiteText = false
}) => {
  return (
    <div className={`${boxLayout} ${isFullWidth ? 'full-width' : ''} button-container`}>
      <button
        onClick={callback}
        disabled={disabled || isLoading}
        className={`${buttonStyles[btnStyle]} button ${isFullWidth ? 'full-width' : ''} ${disabled ? 'disabled' : ''}`}
      >
        <div className="button-content">
          <div
            className="text-container"
          >
            <span
              className={`${whiteText ? 'textWhite' : textOutline[btnStyle]} button-text ${disabled ? 'disabled' : ''}`}
              style={{ color: textColor }}
            >
              {isLoading ? 'Cargando...' : title}
            </span>
          </div>
        </div>
      </button>
    </div>
  );
};

export default MainButton;
