import { Booking, BookingDto, Restaurant, RestaurantDto, Schedule, ScheduleDto } from "../../types/types";

const scheduleAdapter = (schedule: ScheduleDto[]): Schedule[] => {
    // return is always a length 7 array of Schedule, but schedule can be empty
    const res: Schedule[] = Array(7).fill({dinner: [], lunch: []});
  
    if (schedule && schedule.length > 0) {
      schedule.forEach((item, index) => {
        res[index] = {
          dinner: item.dinner,
          lunch: item.lunch
        };
      });
    }
    return res;
  };
  export const bookingDtoToBookingAdapter = (booking: BookingDto): Booking => {
    return {
      ...booking,
      table: {
        ...booking.table
      },
      date: new Date(booking.date)
    };
  };
export const restaurantAdapter = (
    restaurant: RestaurantDto,
    id: number
  ): Restaurant => {
    console.log({restaurant});
    
    const data: Restaurant = {
      active: restaurant.active,
      address: {
        location: restaurant.address.location,
        address: restaurant.address.address,
        addressNumber: restaurant.address.addressNumber,
        zipCode: restaurant.address.zipCode
      },
      automatedBooking: restaurant.automatedBooking,
      description: restaurant.description,
      bookingDuration: restaurant.durationReserveMinutes || 0,
      schedule: scheduleAdapter(restaurant.schedule),
      details: {
        styles: restaurant.details?.styles || [],
        bannersImages: restaurant.details?.bannersImages || [],
        foodImages: restaurant.details?.foodImages || [],
        pricesRange: {
          maximum: restaurant.details?.pricesRange?.maximum || 500,
          minimum: restaurant.details?.pricesRange?.minimum || 0,
          currencyCode: restaurant.details?.pricesRange?.currencyCode || 'EUR'
        },
        services: restaurant.details?.services || [],
        banner: restaurant.details?.banner || ''
      },
      id: id,
      name: restaurant.name,
      cancelationPolicy: restaurant.cancelationPolicy
        ? {
            minutes: restaurant.cancelationPolicy.minutes,
            description: restaurant.cancelationPolicy.description
          }
        : undefined,
  
      verified: restaurant.verified,
      ledger: restaurant.ledger,
      // tables: restaurant.tables,
      zones: restaurant.zones,
      bookings: restaurant.bookings.map(booking => {
        return bookingDtoToBookingAdapter(booking);
      }),
      contacts: restaurant.contacts
    };
    return data;
  };