import React, { useEffect } from "react";
import DateInput from "../../components/DateInput/index.tsx";
import TimeInput from "../../components/TimeInput/index.tsx";
import PartySizeInput from "../../components/PartySizeInput/index.tsx";
import AllergiesInput from "../../components/AllergiesInput/index.tsx";
import ClientInput from "../../components/ClientInput/index.tsx";
import CommentInput from "../../components/CommentInput/index.tsx";
import { useBookingForm } from "../../hooks/useBookingForm/useBookingForm.ts";
import "./styles.css";
import ReservationDisplay from "../../components/ReservationDisplay/index.tsx";
import { APP_FORM_STEPS } from "../../utils/constants.ts";
import { DefaultRestaurant, StepForDisplay } from "../../types/types.ts";
import ConfirmBooking from "../../components/ConfirmBooking/index.tsx";
import { useRestaurantAvailability } from "../../hooks/useRestaurantAvailability/useRestaurantAvailability.ts";
import StepNavigatorMenu from "../../components/StepNavigationMenu/index.tsx";
import { useRestaurant } from "../../hooks/useRestaurant/useRestaurant.ts";
import Loading from "../Loading/index.tsx";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/Logo.tsx";
import { useRestaurantMaxSeats } from "../../hooks/useRestaurantMaxSeats/useRestaurantMaxSeats.ts";
import { Controller } from "react-hook-form";

export const stepsForDisplay: StepForDisplay[] = [
  {
    id: "0",
    name: "Cliente",
    type: APP_FORM_STEPS.CLIENT,
    icon: "person",
    label: "Cliente",
    isCompleted: false,
    isCurrentStep: false,
  },
  {
    id: "1",
    name: "Fecha",
    type: APP_FORM_STEPS.DAY,
    icon: "calendar-outline",
    label: "Día",
    isCompleted: false,
    isCurrentStep: false,
  },
  {
    id: "2",
    name: "Comensales",
    type: APP_FORM_STEPS.PEOPLE_AMOUNT,
    icon: "people-outline",
    label: "Cantidad de personas",
    isCompleted: false,
    isCurrentStep: false,
  },
  {
    id: "3",
    name: "Hora",
    type: APP_FORM_STEPS.TIME,
    icon: "time-outline",
    label: "Hora",
    isCompleted: false,
    isCurrentStep: false,
  },
  {
    id: "4",
    name: "Alergias",
    type: APP_FORM_STEPS.ALLERGIES,
    icon: "alert-circle-outline",
    label: "Alergias",
    isCompleted: false,
    isCurrentStep: false,
  },
  {
    id: "5",
    name: "Comentario",
    type: APP_FORM_STEPS.COMMENT,
    icon: "chatbubble-outline",
    label: "Comentarios",
    isCompleted: false,
    isCurrentStep: false,
  },
  {
    id: "6",
    name: "Confirmar reserva",
    type: APP_FORM_STEPS.CONFIRM,
    icon: "chatbubble-outline",
    label: "Comentarios",
    isCompleted: false,
    isCurrentStep: false,
  },
];

const BookingForm: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const restaurantId = parseInt(queryParams.get("id") || "1")
  const { restaurant, loading, fetchRestaurant } = useRestaurant();
  useEffect(() => {
    if (restaurantId) {
      fetchRestaurant(restaurantId);
    }
  }, [restaurantId]);

  const {
    availability,
    loading: isPendingAvailability,
    fetchAvailability,
  } = useRestaurantAvailability();
  const {
    maxSeats,
    loading: isPendingMaxSeats,
    fetchMaxSeats,
  } = useRestaurantMaxSeats();
  const {
    currentStepNumber,
    handleMenuButtonPress,
    isMenuButtonDisabled,
    getStepLabel,
    isStepCompleted,
    setValue,
    setPartialClientData,
    booking,
    getMenuButtonLabel,
    isSubmitting,
    control,
    errors,
    trigger,
  } = useBookingForm({ restaurantId });

  const renderStepContent = () => {
    const currentStep = stepsForDisplay[currentStepNumber];
    switch (currentStep.type) {
      case APP_FORM_STEPS.CLIENT:
        return (
          <Controller
            name="client"
            control={control}
            defaultValue={booking.client}
            render={({ field }) => (
              <ClientInput
                onChange={setPartialClientData}
                restaurantId={restaurant.id}
                value={booking.client}
                error={errors.client?.message}
                trigger={() => trigger("client")}
              />
            )}
            rules={{
              required: true,
              validate: {
                name: (value) => {
                  if (!value || !value.name) return "El nombre es obligatorio";
                  if (value.name.length < 3)
                    return "El nombre debe tener al menos 3 caracteres";
                  if (value.name.length >= 40)
                    return "El nombre debe tener menos de 40 caracteres";
                  if (!value || !value.email) return "El email es obligatorio";
                  if (value.email.length >= 254)
                    return "El email debe tener como máximo 254 caracteres";
                  if (
                    !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i.test(
                      value.email
                    )
                  )
                    return "El email no es válido";
                  if (!value || !value.phoneNumber)
                    return "El teléfono es obligatorio";
                  if (value.phoneNumber.length < 7)
                    return "El teléfono no es válido";
                  return true;
                },
              },
            }}
          />
        );
      case APP_FORM_STEPS.DAY:
        return (
          <DateInput
            onChange={(date) => {
              setValue("date", date);
              fetchAvailability({
                restaurantId: restaurant.id,
                date: date.toISOString().split("T")[0],
              });
              fetchMaxSeats({
                restaurantId: restaurant.id,
                date: date.toISOString().split("T")[0],
              });
            }}
            value={booking.date}
          />
        );
      case APP_FORM_STEPS.PEOPLE_AMOUNT:
        return (
          <PartySizeInput
            value={booking.partySize!}
            onChange={(size) => {
              setValue("partySize", size);
            }}
            maxSeats={maxSeats}
            isLoaded={!isPendingMaxSeats}
          />
        );
      case APP_FORM_STEPS.TIME:
        return (
          <TimeInput
            onChange={(time) => {
              setValue("time", time);
            }}
            value={booking.time!}
            availability={availability}
            isLoaded={!isPendingAvailability}
          />
        );
      case APP_FORM_STEPS.ALLERGIES:
        return (
          <AllergiesInput
            value={booking.allergies}
            onChange={(allergies) => {
              setValue("allergies", allergies);
            }}
          />
        );
      case APP_FORM_STEPS.COMMENT:
        return (
          <CommentInput
            value={booking.comment!}
            onChange={(comment) => {
              setValue("comment", comment);
            }}
          />
        );
      case APP_FORM_STEPS.CONFIRM:
        return (
          <ConfirmBooking
            stepsForDisplay={stepsForDisplay.map((step, index) => {
              return {
                ...step,
                isCurrentStep: index === currentStepNumber,
                label: getStepLabel(step.type),
                isCompleted: isStepCompleted(step.type),
              };
            })}
            restaurant={restaurant!}
            name={booking.client?.name!}
          />
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="App">
        <Loading />
      </div>
    );
  }

  return (
    <div className="full-page-container">
      <div className="container">
        <div className="display-container">
          <ReservationDisplay
            restaurant={restaurant ? restaurant : DefaultRestaurant}
            stepsForDisplay={stepsForDisplay.map((step, index) => {
              return {
                ...step,
                isCurrentStep: index === currentStepNumber,
                label: getStepLabel(step.type),
                isCompleted: isStepCompleted(step.type),
              };
            })}
            currentStepNumber={currentStepNumber}
          />
        </div>
        <div className="step-container">
          <div className="step-content-container">{renderStepContent()}</div>
          <div className="step-navigator-container">
            <StepNavigatorMenu
              onButtonPress={handleMenuButtonPress}
              getButtonLabel={getMenuButtonLabel}
              isButtonDisabled={isMenuButtonDisabled}
              isSubmitting={isSubmitting}
            />
          </div>
        </div>
      </div>
      <div className="logo-container">
        <Logo size={80} />
      </div>
    </div>
  );
};

export default BookingForm;
