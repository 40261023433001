import React, { useState } from 'react';
import './styles.css';

export interface InputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  name?: string;
}

const Input: React.FC<InputProps> = ({ value, onChange, placeholder, name }) => {
  const [ isFocused, setIsFocused ] = useState<boolean>(false)
  
  return (
    <div className={`input-wrapper ${isFocused && 'focus'}`}>
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        className={`input ${isFocused && 'focus'}`}
        onFocus={()=>setIsFocused(true)}
        onBlur={()=>setIsFocused(false)}
      />
    </div>
  );
};

export default Input;
