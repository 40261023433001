import React from "react";
import "./styles.css";
import {
  MealTime,
  MealTimeAvailability,
  RestaurantAvailability,
  Slot,
} from "../../types/types.ts";
import SelectButton from "./components/SelectButton/index.tsx";
import { COLORS } from "../../utils/colors.tsx";
import { MoonOutline, SunnyOutline } from "react-ionicons";

interface TimeInputProps {
  value?: MealTime;
  onChange: (value: MealTime) => void;
  availability?: RestaurantAvailability;
  isLoaded?: boolean;
}

const TimeInput: React.FC<TimeInputProps> = ({
  onChange,
  value,
  availability,
  isLoaded,
}) => {
  interface TimeFrameBoxProps {
    title: string;
    data: MealTimeAvailability[];
    iconName: string;
    value?: MealTime;
    slot: Slot;
  }

  const renderIcon = (iconName: string) => {
    switch (iconName) {
      case "sunny":
        return (
          <SunnyOutline height="22px" width="22px" color={COLORS.lightblue} />
        );
      case "moon":
        return (
          <MoonOutline height="22px" width="22px" color={COLORS.lightblue} />
        );
      default:
        return null;
    }
  };

  const TimeFrameBox: React.FC<TimeFrameBoxProps> = ({
    title,
    data,
    iconName,
    slot,
  }) => (
    <div className="time-frame-box">
      <div className="slot-title">
        {renderIcon(iconName)}
        <span className="head-text">{title}</span>
      </div>
      <div className="time-frame-list-container">
        {data.map((item) => (
          <SelectButton
            selected={item.hour === value?.hour}
            text={item.hour}
            callback={() => {
              onChange({ ...item, slot });
            }}
            key={item.hour}
          />
        ))}
      </div>
    </div>
  );

  if (!isLoaded) {
    return (
      <div className="time-input-container-loading">
        <span className="no-booking-available-text">
          {"Cargando horarios disponibles..."}
        </span>
      </div>
    );
  }

  if (!availability) {
    return (
      <div className="time-input-container-loading">
        <span className="no-booking-available-text">
          {"No hay horarios disponibles"}
        </span>
      </div>
    );
  }

  return (
    <div className="time-input-container">
      <div className="title-container">
        <span className="title">Selecciona un horario</span>
      </div>
      <div className="time-select">
        <div>
          {availability.lunch.length === 0 ? (
            <span className="no-booking-available-text">
              {"No hay horarios disponibles para la comida"}
            </span>
          ) : (
            <TimeFrameBox
              title={"Comida"}
              iconName={"sunny"}
              data={availability.lunch}
              slot={"lunch"}
            />
          )}
        </div>
        <div>
          {availability.dinner.length === 0 ? (
            <span className="no-booking-available-text">
              {"No hay horarios disponibles para la cena"}
            </span>
          ) : (
            <TimeFrameBox
              title={"Cena"}
              iconName={"moon"}
              data={availability.dinner}
              slot={"dinner"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeInput;
