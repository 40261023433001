export enum APP_FORM_STEPS {
  CLIENT = "client",
  DAY = "day",
  WEEKDAY = "weekday",
  PEOPLE_AMOUNT = "peopleAmount",
  TIME = "time",
  ALLERGIES = "allergies",
  COMMENT = "comment",
  PREFERED_ZONE = "preferredZone",
  TIME_FRAME = "timeFrame",
  NOTICE = "notice",
  CONFIRM = "confirmBooking"
}
