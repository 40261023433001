import React from 'react';

interface Props {
    size: number;
    color?: string;
}

const MesaOutline: React.FC<Props> = ({ size, color }) => {
    return (
        <div style={{ borderColor: 'black', borderWidth: 0 }}>
            <svg
                id="table_00000145056376602587697220000014389354570212852392_"
                x="0px"
                y="0px"
                width={size}
                height={size}
                viewBox="0 0 24 18"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    id="tablepath"
                    d="M6.9 4.9c.1 0 .1 0 0 0 .6.1.8.7.8 1.1L5.5 16.5c-.1.5-.5.7-1 .6h-.2c-.5-.1-.7-.6-.6-1.1L5.9 5.6c.1-.5.6-.8 1-.7zm11.2.7l2.3 10.5c.1.5-.2.9-.6 1.1h-.1c-.5.1-.9-.1-1.1-.5v-.1L16.3 6c-.1-.5.2-.9.6-1.1h.1c.4-.1.9.2 1.1.7zM21.9.8c.5 0 1 .5 1 1s-.5 1-1 1H2.1c-.5 0-1-.5-1-1s.5-1 1-1h19.8z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill={color || 'black'}
                />
            </svg>
        </div>
    );
};

export default MesaOutline;
