import { restaurantAdapter } from "./utils.ts";
import { getRestaurant } from "../../services/RestaurantService.ts";
import { DefaultRestaurant, Restaurant } from "../../types/types.ts";
import { useState } from "react";

export const useRestaurant = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [restaurant, setRestaurant] = useState<Restaurant>(DefaultRestaurant);
  const fetchRestaurant = async (restaurantId: number) => {
    setLoading(true);
    if (!restaurantId) {
      throw new Error("Restaurant ID needed");
    }
    try {
      const data = (await getRestaurant({ restaurantId })).data;
      console.log("[RestaurantData]:", data);
      const restaurant = restaurantAdapter(
        (await getRestaurant({ restaurantId })).data,
        restaurantId
      );
      if (!restaurant) {
        throw new Error("Restaurant fetching failed");
      }
      setRestaurant(restaurant);
      setLoading(false);
    } catch (e) {
      console.error({ e });
    }
  };

  return { restaurant, loading, fetchRestaurant };
};
