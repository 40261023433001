const bookingColors = {
  finish: '#CA56B8',
  booked: '#005E7E',
  cancel: '#909090',
  pending: '#F2C80F',
  ongoing: '#F46D5A',
  noshow: '#9E695C',
  confirmed: '#3CAC46',
  lightconfirmed: 'rgba(96,190,104,0.2)',
  lightfinish: 'rgba(202, 86, 184, 0.2)',
  lightbooked: 'rgba(0, 94, 126, 0.2)',
  lightcancel: 'rgba(144, 144, 144, 0.2)',
  lightpending: 'rgba(242,200,15, 0.2)',
  lightongoing: 'rgba(244, 109, 90, 0.1)',
  lightnoshow: 'rgba(158,105,92,0.2)'
};

const solidColors = {
  white: '#fff',
  green: '#3CAC46',
  freeTable: '#60BD68',
  gray: '#A2C1C7',
  red: '#F46D5A',
  lightblue: '#00ADCB',
  lighterblue: '#B6CBCD',
  darkyellow: '#F2C80F',
  mutedTeal: '#CDE8E4',
  lightbluelight: 'rgba(0, 173, 203, 0.2)',
  lightgray: 'rgba(151, 151, 151, 0.2)',
  lightgreen: 'rgba(60, 172, 70, 0.1)',
  lightblack: 'rgba(30, 30, 30, 0.5)',
  black: '#000',
  yellow: '#FEFBF1',
  yellowButton: '#F2C80F'
};

const progressBarColors = {
  bronze: '#A86F4E',
  silver: '#C0C0C0',
  gold: '#FFD700',
  platinum: '#E5E4E2'
};

const warningColors = {
  mustard: '#B1920A',
  lightmustard: '#FCF5D0'
};

export const COLORS = {
  primaryblue: '#005E7E',
  secondary: '#096285',
  muted: '#e4f1f3',
  mutedDark: '#d0e7eb',
  inactiveText: '#05e0ee',
  darkBlueBackground: '#00344f',
  ...progressBarColors,
  ...bookingColors,
  ...solidColors,
  ...warningColors
};
export const dynamicColor = (estado: string) => {
  switch (estado) {
    case 'en-curso':
      return COLORS.lightongoing;
    case 'pendiente':
      return COLORS.lightpending;
    case 'reservada':
      return COLORS.lightbooked;
    case 'confirmada':
      return COLORS.lightconfirmed;
    case 'llegada':
      return COLORS.yellow;
    case 'terminada':
      return COLORS.lightfinish;
    case 'cancelada':
      return COLORS.lightcancel;
    case 'no-show':
      return COLORS.lightnoshow;
    default:
      return COLORS.lightcancel;
  }
};

export const dynamicColor2 = (estado: string) => {
  switch (estado) {
    case 'en-curso':
      return COLORS.ongoing;
    case 'pendiente':
      return COLORS.pending;
    case 'reservada':
      return COLORS.booked;
    case 'confirmada':
      return COLORS.confirmed;
    case 'llegada':
      return COLORS.white;
    case 'terminada':
      return COLORS.finish;
    case 'cancelada':
      return COLORS.cancel;
    case 'no-show':
      return COLORS.noshow;
    default:
      return COLORS.gray;
  }
};
export const makeColors = (num: number): any => {
  switch (num) {
    case 0:
      return COLORS.lightblue;
    case 1:
      return COLORS.lightongoing;
    case 2:
      return COLORS.lightpending;
    case 3:
      return COLORS.lightbooked;
    case 4:
      return COLORS.lightconfirmed;
    case 5:
      return COLORS.lightfinish;
    case 6:
      return COLORS.lightcancel;
    case 7:
      return COLORS.lightnoshow;
    default:
      return makeColors(num - 7);
  }
};

enum BookingState {
  PENDING = '1', //pendiente
  BOOKED = '2', //reservada
  CONFIRMED = '3', //confirmada
  ON_GOING = '4', //en curso. para el cliente no existe este estado.
  NO_SHOW = '5', //no presentado
  FINISHED = '6', //terminada. para el cliente: disfrutada.
  REJECTED = '7', //rechazada por el manager
  CANCELLED = '8' //cancelada por el usuario
}

export const bookingStateColor = (state: BookingState) => {
  switch (state) {
    case BookingState.PENDING:
      return COLORS.pending;
    case BookingState.BOOKED:
      return COLORS.booked;
    case BookingState.CONFIRMED:
      return COLORS.confirmed;
    case BookingState.ON_GOING:
      return COLORS.ongoing;
    case BookingState.NO_SHOW:
      return COLORS.noshow;
    case BookingState.FINISHED:
      return COLORS.finish;
    case BookingState.REJECTED:
      return COLORS.cancel;
    case BookingState.CANCELLED:
      return COLORS.cancel;
    default:
      return COLORS.gray;
  }
};

export const bookingStateLightColor = (state: BookingState) => {
  switch (state) {
    case BookingState.PENDING:
      return COLORS.lightpending;
    case BookingState.BOOKED:
      return COLORS.lightbooked;
    case BookingState.CONFIRMED:
      return COLORS.lightconfirmed;
    case BookingState.ON_GOING:
      return COLORS.lightongoing;
    case BookingState.NO_SHOW:
      return COLORS.lightnoshow;
    case BookingState.FINISHED:
      return COLORS.lightfinish;
    case BookingState.REJECTED:
      return COLORS.lightcancel;
    case BookingState.CANCELLED:
      return COLORS.lightcancel;
    default:
      return COLORS.lightgray;
  }
};
