import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import BookingForm from "./pages/ReservationForm/index.tsx";
import SuccessScreen from "./pages/ReservationSuccess/index.tsx";
import { useRestaurant } from "./hooks/useRestaurant/useRestaurant.ts";
import Loading from "./pages/Loading/index.tsx";

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<BookingForm />} />
          <Route path="/success" element={<SuccessScreen />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
