import React, { useEffect, useRef } from "react";
import ReservationDisplayStep from "./components/ReservationDisplayStep/index.tsx";
import ReservationFormHeader from "./components/ReservationFormHeader/index.tsx";
import { Restaurant, StepForDisplay } from "../../types/types.ts";
import "./styles.css";

interface ReservationDisplayProps {
  restaurant: Restaurant;
  currentStepNumber: number;
  stepsForDisplay: StepForDisplay[];
}

const ReservationDisplay: React.FC<ReservationDisplayProps> = ({
  restaurant,
  stepsForDisplay,
  currentStepNumber,
}) => {
  const scrollViewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const reachedHalf = 5 > stepsForDisplay.length / 2;
    if (reachedHalf) {
      scrollViewRef.current?.scrollTo({
        top: scrollViewRef.current.scrollHeight,
        behavior: "smooth",
      });
    } else {
      scrollViewRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentStepNumber]);

  return (
    <div className="scroll-view" ref={scrollViewRef}>
      <div className="form-info-box">
        <ReservationFormHeader restaurant={restaurant} />
        <div className="steps-display-container">
          {stepsForDisplay
            .slice(0, -1)
            .map(({ icon, id, isCompleted, label }, index) => {
              const isSelected = currentStepNumber === index;
              return (
                <ReservationDisplayStep
                  icon={icon}
                  isCompleted={isCompleted}
                  isSelected={isSelected}
                  key={id}
                  label={label}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ReservationDisplay;
