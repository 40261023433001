import React from "react";
import "./styles.css";
import { Restaurant, StepForDisplay } from "../../types/types";

interface ConfirmBookingProps {
  stepsForDisplay: StepForDisplay[];
  restaurant: Restaurant;
  name: string;
}

const ConfirmBooking: React.FC<ConfirmBookingProps> = ({
  stepsForDisplay,
  restaurant,
  name,
}) => {
  return (
    <div className="animated-view">
      <div>
        <p className="inner-top-text">{`¡Hola ${
          name.split(" ")[0]
        }! ¿Todo correcto?`}</p>
        <span className="inner-top-text">{`Confirma tu reserva en:`}</span>
      </div>
      <div>
        <p className="inner-restaurant-name">{`${restaurant.name}`}</p>
        <p className="inner-restaurant-address">{`${restaurant.address.address}, ${restaurant.address.addressNumber}, ${restaurant.address.zipCode} ${restaurant.address.location.city}, ${restaurant.address.location.province}`}</p>
      </div>
      <div className="scroll-container">
        {stepsForDisplay.slice(0, -1).map((step, index) => (
          <div key={index} className="step-box">
            <span className="inner-step-text">{step.name}</span>
            <span className="inner-description-text">{step.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConfirmBooking;
