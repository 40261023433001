import {
  Allergies,
  CustomerData,
  CustomerDetails,
  MealTime,
  Table,
} from "../../types/types";

export const getDateCompletedLabel = (date: Date) => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const getPartySizeCompletedLabel = (partySize: number) => {
  return partySize === 1 ? "1 persona" : `${partySize} Personas`;
};

export const getTimeStepCompletedLabel = (time: MealTime) => {
  return time.hour;
};

export const getClientCompleteLabel = (client?: CustomerData) => {
  return client?.name || "Datos personales";
};

export const getTableCompleteLabel = (table: Table) => {
  return table?.name;
};

export const mapAllergies = (allergies: Allergies) => {
  switch (allergies) {
    case "no":
      return "No";
    case "yes":
      return "Si";
    case "unconfirmed":
      return "Confirmar más tarde";
    default:
      return "#Error";
  }
};

export const customerInfoAdapter = (data: CustomerData): CustomerDetails => {
  const { email } = data;
  return {
    ...data,
    emails: [email],
  };
};

export const momentToLocalFirstLetterUpperCase = (date: Date) => {
  return date.toLocaleDateString("es-ES", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
