import { CreateBooking } from "../../types/types.ts";
import { createBooking } from "../../services/RestaurantService.ts";
import { useState } from "react";

interface SubmitBookingParams {
  onSuccess: () => void;
  onError: () => void;
}

export const mockedBooking: CreateBooking = {
  allergies: "unconfirmed",
  bracket: "dinner",
  comment: "",
  date: "2024-06-20 19:00",
  numberOfPeople: 3,
  restaurantId: 1,
  customerInfo: {
    restaurantId: 1,
    name: "Gonzalo",
    phoneNumber: "112345567890",
    emails: ["asdasd@dfsdf.com"],
  },
};

export const useSubmitBooking = ({
  onSuccess,
  onError,
}: SubmitBookingParams) => {
  const [isPending, setIsPending] = useState<boolean>(false);

  const submitBooking = async (booking: CreateBooking) => {
    setIsPending(true);
    try {
      if (!booking) {
        throw new Error("Booking creation failed");
      }
      const res = await createBooking({ ...booking });
      console.log({res});
      
      onSuccess();
    } catch (e) {
      console.error(e);
      onError();
      } finally {
      setIsPending(false);
    }
  };

  return { submitBooking, isPending };
};
