import React from "react";
import './styles.css';

const CommentInput: React.FC<{
  value: string;
  onChange: (comment: string) => void;
}> = ({ value, onChange }) => {
  return (
    <div className="step-content">
      <div className="title-container">
        <span className="title">Comentarios</span>
      </div>
      <div className="comment-container">
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="text-area"
          placeholder="Escribe aquí"
        />
      </div>
    </div>
  );
};

export default CommentInput;
