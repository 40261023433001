import React, { useEffect } from "react";
import "./styles.css";
import Logo from "../../assets/Logo.tsx";
import { useLocation } from "react-router-dom";
import { useRestaurant } from "../../hooks/useRestaurant/useRestaurant.ts";
import Loading from "../Loading/index.tsx";
import SuccessHero from "../../assets/SuccessHero/SuccessHero.tsx";
import GoogleMapsLink from "../../components/GoogleMapsLink/index.tsx";
import ResponsiveImage from "../../assets/SuccessHero/SuccessHero.tsx";
import logoSolid from "../../assets/mesapremium_logo_squared.png";

const SuccessScreen: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const restaurantId = parseInt(queryParams.get("id") || '1')
  const { restaurant, loading, fetchRestaurant } = useRestaurant();
  useEffect(() => {
    if (restaurantId) {
      fetchRestaurant(restaurantId);
    }
  }, [restaurantId]);
  const handleDownload = () => {
    // const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.example.yourapp';
    const playStoreUrl = 'https://www.google.com/';
    window.open(playStoreUrl, '_blank');
  };
  if (loading) {
    return (
      <div className="App">
        <Loading />
      </div>
    );
  }
  return (
    <div className="full-page-container">
      <div className="container">
        <div className="success-wrapper">
          <div className="success-top">
            <div className="success-image">
              <SuccessHero />
            </div>
            <div className="success-message">
              <span className="success-title">{"Reserva confirmada"}</span>
              <div className="restaurant-data">
                <span className="restaurant-name">{`${restaurant.name}`}</span>
                <GoogleMapsLink
                  latitude={restaurant.address.location.latitude}
                  longitude={restaurant.address.location.longitude}
                >
                  <span className="restaurant-address">
                    {`${restaurant.address.address}, ${restaurant.address.addressNumber}, ${restaurant.address.zipCode}`}<br/>
                  </span>
                  <span className="restaurant-address">
                    {`${restaurant.address.location.city}, ${restaurant.address.location.province}`}
                  </span>
                </GoogleMapsLink>
              </div>
            </div>
          </div>
          <div className="success-link">
            <span className="disclaimer">
              {
                "Si quieres un tratamiento premium, descarga nuestra la app y tendrás muchas más opciones para gestionar tu reserva."
              }
            </span>
            <div className="button-wrapper">
              <button className="link-button" onClick={handleDownload}>
                <div className="logo-solid-container">
                  <ResponsiveImage src={logoSolid} />
                </div>
                <span className="link-text">{"Descarga Mesa Premium"}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="logo-container">
        <Logo size={80} />
      </div>
    </div>
  );
};

export default SuccessScreen;
