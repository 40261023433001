import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  customerInfoAdapter,
  getClientCompleteLabel,
  getDateCompletedLabel,
  getPartySizeCompletedLabel,
  getTimeStepCompletedLabel,
  mapAllergies,
} from "./utils.ts";
import { APP_FORM_STEPS } from "../../utils/constants.ts";
import { useSubmitBooking } from "../useSubmitBooking/useSubmitBooking.ts";
import {
  Allergies,
  CreateBooking,
  CustomerData,
  MealTime,
  StepNavigatorMenuButtonType,
} from "../../types/types.ts";
import { useNavigate } from "react-router-dom";

export interface CreateManagerBooking {
  client: CustomerData | undefined;
  date: Date | undefined;
  partySize: number | undefined;
  time: MealTime | undefined;
  allergies: Allergies | undefined;
  comment: string | undefined;
}

const DEFAULT_VALUES: CreateManagerBooking = {
  client: undefined,
  date: undefined,
  partySize: undefined,
  time: undefined,
  allergies: undefined,
  comment: undefined,
};

interface UseBookingFormProps {
  restaurantId: number;
}

export const useBookingForm = ({ restaurantId }: UseBookingFormProps) => {
  const {
    control,
    handleSubmit,
    formState,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
    trigger,
  } = useForm<CreateManagerBooking>({
    defaultValues: DEFAULT_VALUES,
  });
  const navigate = useNavigate();

  const [currentStepNumber, setCurrentStepNumber] = useState<number>(0);
  const booking = watch();
  const comment = watch("comment");

  const resetForm = () => {
    reset(DEFAULT_VALUES);
    setCurrentStepNumber(0);
  };

  const handleMenuButtonPress = (button: StepNavigatorMenuButtonType) => {
    switch (button) {
      case StepNavigatorMenuButtonType.RIGHT:
        return () => {
          if (currentStepNumber === 6) {
            console.log({ booking });
            submit();
            return;
          }
          if (currentStepNumber === 6 && !comment) {
            setValue("comment", "");
          }
          setCurrentStepNumber((prev) => prev + 1);
        };
      case StepNavigatorMenuButtonType.LEFT:
        return () => {
          setCurrentStepNumber((prev) => prev - 1);
        };
    }
  };

  const getStepLabel = useCallback(
    (key: APP_FORM_STEPS): string => {
      switch (key) {
        case APP_FORM_STEPS.DAY:
          return booking.date !== undefined
            ? getDateCompletedLabel(booking.date)
            : "Día";
        case APP_FORM_STEPS.PEOPLE_AMOUNT:
          return booking.partySize !== undefined
            ? getPartySizeCompletedLabel(booking.partySize)
            : "Cantidad de personas";
        case APP_FORM_STEPS.TIME:
          return booking.time !== undefined
            ? getTimeStepCompletedLabel(booking.time)
            : "Hora";
        case APP_FORM_STEPS.ALLERGIES:
          return booking.allergies !== undefined
            ? mapAllergies(booking.allergies)
            : "Alergias";
        case APP_FORM_STEPS.CLIENT:
          return booking.client
            ? getClientCompleteLabel(booking.client)
            : "Cliente";
        case APP_FORM_STEPS.COMMENT:
          return !!booking.comment
            ? booking.comment
            : currentStepNumber > 5
            ? "No hay comentarios"
            : "Comentario";
        case APP_FORM_STEPS.CONFIRM:
          return "Reserva";
        default:
          return "#Error";
      }
    },
    [booking, currentStepNumber]
  );

  const getValue = (key: APP_FORM_STEPS) => {
    switch (key) {
      case APP_FORM_STEPS.CLIENT:
        return booking.client;
      case APP_FORM_STEPS.DAY:
        return booking.date;
      case APP_FORM_STEPS.PEOPLE_AMOUNT:
        return booking.partySize;
      case APP_FORM_STEPS.TIME:
        return booking.time;
      case APP_FORM_STEPS.ALLERGIES:
        return booking.allergies;
      case APP_FORM_STEPS.COMMENT:
        return booking.comment;
      default:
        return "#Error";
    }
  };

  const isStepCompleted = useCallback(
    (key: APP_FORM_STEPS): boolean => {
      switch (key) {
        case APP_FORM_STEPS.CLIENT: {
          return (
            !!booking.client?.name &&
            !!booking.client?.email &&
            !!booking.client?.phoneNumber &&
            !errors.client
          );
        }
        case APP_FORM_STEPS.DAY:
          return booking.date !== undefined;
        case APP_FORM_STEPS.PEOPLE_AMOUNT:
          return booking.partySize !== undefined;
        case APP_FORM_STEPS.TIME:
          return booking.time !== undefined;
        case APP_FORM_STEPS.ALLERGIES:
          console.log(
            "ALLERGIES",
            booking.allergies,
            booking.allergies !== undefined,
            currentStepNumber >= 4,
            booking.allergies !== undefined && currentStepNumber >= 4
          );
          return booking.allergies !== undefined && currentStepNumber >= 4;
        case APP_FORM_STEPS.COMMENT:
          return currentStepNumber >= 5;
        default:
          return false;
      }
    },
    [booking, currentStepNumber, errors]
  );

  const isCurrentStepCompleted = useMemo(() => {
    switch (currentStepNumber) {
      case 0:
        return isStepCompleted(APP_FORM_STEPS.CLIENT);
      case 1:
        return isStepCompleted(APP_FORM_STEPS.DAY);
      case 2:
        return isStepCompleted(APP_FORM_STEPS.PEOPLE_AMOUNT);
      case 3:
        return isStepCompleted(APP_FORM_STEPS.TIME);
      case 4:
        return isStepCompleted(APP_FORM_STEPS.ALLERGIES);
      case 5:
        return isStepCompleted(APP_FORM_STEPS.COMMENT);
      default:
        return true;
    }
  }, [currentStepNumber, isStepCompleted]);

  const getMenuButtonLabel = useCallback(
    (button: StepNavigatorMenuButtonType) => {
      switch (button) {
        case StepNavigatorMenuButtonType.RIGHT:
          return currentStepNumber === 6 ? "Confirmar" : "Siguiente";
        case StepNavigatorMenuButtonType.LEFT:
          return "Anterior";
      }
    },
    [currentStepNumber]
  );

  const handleNextStep = useCallback(() => {
    setCurrentStepNumber((prev) => prev + 1);
  }, []);

  const { submitBooking, isPending } = useSubmitBooking({
    onSuccess: () => {
      navigate(`/success?id=${restaurantId}`);
      resetForm();
    },
    onError: () => {
      console.log("Error submitting booking");
    },
  });

  const submit = useCallback(() => {
    handleSubmit((data) => {
      const params: CreateBooking = {
        date: data.date?.toISOString().split("T")[0] + " " + data.time?.hour,
        bracket: data.time?.slot!,
        numberOfPeople: data.partySize!,
        allergies: data.allergies!,
        comment: data.comment!,
        restaurantId: restaurantId,
        customerInfo: data.client && customerInfoAdapter(data.client),
      };
      submitBooking(params);
    })();
  }, [handleSubmit]);

  const isMenuButtonDisabled = useCallback(
    (button: StepNavigatorMenuButtonType): boolean => {
      switch (button) {
        case StepNavigatorMenuButtonType.RIGHT:
          return isPending || !isCurrentStepCompleted;
        case StepNavigatorMenuButtonType.LEFT:
          return isPending || currentStepNumber === 0;
      }
    },
    [currentStepNumber, isCurrentStepCompleted, isPending]
  );

  const setPartialClientData = useCallback(
    (newCustomerData: Partial<CustomerData>) => {
      setValue("client", {
        ...getValues("client"),
        ...newCustomerData,
      });
    },
    [getValues, setValue]
  );

  useEffect(() => {
    console.log("[USE-EFFECT] Errors", errors.client);
  }, [errors, booking]);

  return {
    control,
    handleSubmit,
    formState,
    setValue,
    setPartialClientData,
    currentStepNumber,
    handleMenuButtonPress,
    isMenuButtonDisabled,
    getStepLabel,
    booking,
    isStepCompleted,
    getMenuButtonLabel,
    handleNextStep,
    watch,
    isSubmitting: isPending,
    getValue,
    errors,
    trigger,
  };
};
