import React from "react";
import "./styles.css";

interface GoogleMapsLinkProps {
  latitude: string;
  longitude: string;
  children: React.ReactNode;
  centered?: boolean;
}

const GoogleMapsLink: React.FC<GoogleMapsLinkProps> = ({
  latitude,
  longitude,
  children,
  centered = true,
}) => {
  const mapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;

  return (
    <a
      href={mapsUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={`google-maps-link ${centered? 'centered': ''}`}
    >
      {children}
    </a>
  );
};

export default GoogleMapsLink;
