import React from "react";
import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
import Moment from "moment";
import "./styles.css";

interface DateInputProps {
  value?: Date;
  onChange: (value: Date) => void;
  containerStyle?: React.CSSProperties;
}

const DateInput: React.FC<DateInputProps> = ({ value, onChange }) => {
  const MIN_DATE = Moment.utc(new Date()).toDate();

  const handleDayPress = (date: Date) => {
    const d = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
    onChange(d);
  };

  return (
    <div className="date-input-container">
      <div className="title-container">
        <h1 className="title">Selecciona una fecha</h1>
      </div>
      <div className="calendar-container">
        <Calendar
          minDate={MIN_DATE}
          value={value}
          onClickDay={handleDayPress}
          formatShortWeekday={(locale, date) => {
            // In Spanish, the first day of the week is Monday
            const weekdays = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
            return weekdays[date.getDay()];
          }}
          formatMonthYear={(locale, date) => {
            // In Spanish, the months are capitalized
            const months = [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ];
            return `${months[date.getMonth()]} ${date.getFullYear()}`;
          }}
          tileClassName={({ date, view }) => {
            // Add class to available days
            if (view === "month") {
              return "react-calendar__tile--available";
            }
          }}
        />
      </div>
    </div>
  );
};

export default DateInput;
