import React from 'react';
import './styles.css';
import MainButton from './components/MainButton/index.tsx';
import { StepNavigatorMenuButtonType } from '../../types/types.ts';

interface StepNavigatorMenuProps {
  onButtonPress: (button: StepNavigatorMenuButtonType) => () => void;
  getButtonLabel: (button: StepNavigatorMenuButtonType) => string;
  isButtonDisabled: (button: StepNavigatorMenuButtonType) => boolean;
  isSubmitting: boolean;
  containerStyle?: React.CSSProperties;
}

const StepNavigatorMenu: React.FC<StepNavigatorMenuProps> = ({
  onButtonPress: handleButtonPress,
  getButtonLabel,
  isButtonDisabled,
  isSubmitting,
}) => {
  return (
    <div className="buttons-container">
      {/* Back button */}
      <MainButton
        btnStyle="outline"
        callback={handleButtonPress(StepNavigatorMenuButtonType.LEFT)}
        disabled={isButtonDisabled(StepNavigatorMenuButtonType.LEFT) || isSubmitting}
        title={getButtonLabel(StepNavigatorMenuButtonType.LEFT)}
      />

      {/* Next/Finish button */}
      <MainButton
        callback={handleButtonPress(StepNavigatorMenuButtonType.RIGHT)}
        disabled={isButtonDisabled(StepNavigatorMenuButtonType.RIGHT)}
        title={getButtonLabel(StepNavigatorMenuButtonType.RIGHT)}
        isLoading={isSubmitting}
      />
    </div>
  );
};

export default StepNavigatorMenu;
