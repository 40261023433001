import { MealTimeAvailability } from "../../types/types";

export const sortMealTimeAvailability = (
    availability: MealTimeAvailability[]
  ): MealTimeAvailability[] => {
    return availability.sort((a, b) => {
      const aHour = parseInt(a.hour.split(':')[0]);
      const aMinute = parseInt(a.hour.split(':')[1]);
      const bHour = parseInt(b.hour.split(':')[0]);
      const bMinute = parseInt(b.hour.split(':')[1]);
      let aTotalMinutes = aHour * 60 + aMinute;
      let bTotalMinutes = bHour * 60 + bMinute;
      // if is between 00:00 and 04:00 then should be at the end, so add 24 hours
      if (aTotalMinutes >= 0 && aTotalMinutes <= 240) {
        aTotalMinutes += 1440;
      }
      if (bTotalMinutes >= 0 && bTotalMinutes <= 240) {
        bTotalMinutes += 1440;
      }
      return aTotalMinutes - bTotalMinutes;
    });
  };